import HeaderBar from "../components/headerBar/headerBar";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import ShoppingCart from "../components/cart/shoppingCart";
import CashBox from "../components/cart/cashBox";
import Thanks from "../components/cart/thanks";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCartCount } from "../redux/cartSlice";

const Cart = () => {
  const { t } = useTranslation();
  const { userDataLoading, logisticData, logisticDataLoading } = useSelector(
    (state) => state.user
  );
  const [scroll, setScroll] = useState(null);
  const [cashRegister, setCashRegister] = useState(null);
  const [showThanks, setShowThanks] = useState(false);
  const dispatch = useDispatch();

  const scrollTop = () => {
    scroll.scrollTop = 0;
  };

  useEffect(() => {
    dispatch(getCartCount());
  }, []);

  if (userDataLoading || logisticDataLoading)
    return <Spinner animation={"border"} size={"lg"} />;

  return !showThanks ? (
    <>
      <HeaderBar
        header={cashRegister ? t("Pop.cashbox") : t("Pop.cart")}
        className="ps-34"
      >
        {cashRegister ? (
          <Button onClick={() => setCashRegister(false)}>
            {t("Pop.backToCart")}
          </Button>
        ) : null}
      </HeaderBar>
      <Row>
        <Col className="gray-bg custom-scroll content-container with-header-bar">
          <PerfectScrollbar containerRef={(x) => setScroll(x)}>
            {!cashRegister ? (
              <ShoppingCart
                setCashRegister={setCashRegister}
                minLogistic={logisticData}
                scrollTop={scrollTop}
              />
            ) : (
              <CashBox
                setShowThanks={setShowThanks}
                cashRegister={cashRegister}
                minLogistic={logisticData}
                scrollTop={scrollTop}
              />
            )}
          </PerfectScrollbar>
        </Col>
      </Row>
    </>
  ) : (
    <Thanks />
  );
};
export default Cart;
