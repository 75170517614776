import axios from "axios";
import React, { useState } from "react";
import CustomTable from "../components/table/customTable";
import HeaderBar from "../components/headerBar/headerBar";
import Button from "react-bootstrap/Button";
import { ReactComponent as Eye } from "../svg/eye-icon.svg";
import { ReactComponent as Clipboard } from "../svg/content_paste_black_24dp.svg";
import { ReactComponent as Back } from "../svg/keyboard_return_black_24dp (2).svg";
import { Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { debounce } from "lodash";
import Form from "react-bootstrap/Form";
import getFile from "../helpers/order/getOrderFile";
import { Link, useNavigate } from "react-router-dom";
import { setOrderNumber, setProducts } from "../redux/reclamationSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PaginationComponent from "../components/pagination";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useQuery } from "@tanstack/react-query";

const Invoices = () => {
  const { t } = useTranslation();

  const [searchPhrase, setSearchPhrase] = useState("");
  const [reclamationLoading, setReclamationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(50);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function getData(info) {
    return axios
      .get(
        `/user/invoice?order[0][column]=3&order[0][dir]=desc${info.queryKey[2]}`,
        {
          params: {
            page: info.queryKey[1],
            limit: 50,
          },
        }
      )
      .then((response) => {
        setTotalCount(response.data.totalCount);

        return response?.data?.items;
      });
  }
  const { data, isPending } = useQuery({
    queryKey: ["invoices", page, searchPhrase],
    queryFn: getData,
  });
  const handleReclamation = (orderNumber) => {
    setReclamationLoading(true);
    axios
      .get("/order/number/" + orderNumber)
      .then((response) => {
        dispatch(setOrderNumber(orderNumber));
        dispatch(setProducts(data?.orderProducts));
        navigate("/new-complaint");
        setReclamationLoading(false);
      })
      .catch(() => {
        setReclamationLoading(false);
      });
  };
  const columns = [
    {
      title: t("Reclamations.nr_invoice"),
      dataKey: "InvoiceNumber",
      width: "16.66%",
    },
    {
      title: t("Reclamations.nr_order"),
      dataKey: "OrderNumber",
      width: "16.66%",
    },
    {
      title: t("Reclamations.date_invoice"),
      dataKey: "IssueDate",
      width: "16.66%",
    },
    {
      title: t("Reclamations.payment_date"),
      dataKey: "PaymentDate",
      width: "16.66%",
    },
    {
      title: t("Reclamations.status"),
      dataKey: "Status",
      width: "16.66%",
      render: (value) => {
        const statusName = data.statuses.find(
          (status) => status.id === parseInt(value)
        )?.name;
        return <>{statusName}</>;
      },
    },
    {
      title: "",
      dataKey: "OrderNumber",
      width: "16.66%",
      render: (value, item) => (
        <div
          className={"w-100 d-flex justify-content-evenly align-items-center"}
        >
          <Overlay msg={t("Invoices.invoice_preview")}>
            <Button
              variant={"link"}
              className={"p-0"}
              onClick={() =>
                getFile(
                  {
                    docNumber: item.InvoiceNumber,
                    docType: "VBRK",
                  },
                  `${t("Pop.invoice_vat")} ${item.InvoiceNumber}`
                )
              }
            >
              <Eye />
            </Button>
          </Overlay>
          <Overlay msg={t("Invoices.preview")}>
            <Link to={"/orders/" + value}>
              <Clipboard />
            </Link>
          </Overlay>
          <Overlay msg={t("invoices.reclamation")}>
            <Button
              variant={"link"}
              disabled={reclamationLoading}
              className={"p-0"}
              onClick={() => handleReclamation(value)}
            >
              {reclamationLoading ? (
                <Spinner animation={"border"} size={"sm"} />
              ) : (
                <Back />
              )}
            </Button>
          </Overlay>
        </div>
      ),
    },
  ];
  const debounceTimeout = 500;
  const debounceSearch = React.useMemo(() => {
    const loadOptions = (event) => {
      setPage(1);
      const value = event.target.value;

      if (value !== "") {
        setSearchPhrase(`&filters[phrase][]=${value}`);
      } else {
        setSearchPhrase("");
      }
      getData();
    };
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout]);
  const Overlay = ({ msg, children }) => (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">{msg}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
  return (
    <>
      <HeaderBar header={t("Menu.invoices")} headerBarID="invoices">
        <Form className="d-flex ms-4 position-relative search-input-mobile">
          <Form.Control
            type="search"
            placeholder={t("Pop.search")}
            className="me-2 pill search ps-5"
            aria-label="Search"
            onChange={debounceSearch}
          />
          <Button variant="link" className="btn-search ps-3">
            {" "}
            <img src="/img/lupa.svg" alt="" />
          </Button>
        </Form>
      </HeaderBar>
      <Row>
        <Col className="gray-bg custom-scroll content-container with-header-bar">
          <PerfectScrollbar>
            <CustomTable
              data={data?.invoices}
              loading={isPending}
              columns={columns}
              className={"mt-5"}
            />
            <PaginationComponent
              setPage={setPage}
              page={page}
              totalCount={totalCount}
              limit={50}
            />
          </PerfectScrollbar>
        </Col>
      </Row>
    </>
  );
};
export default Invoices;
