import { currencyFormatter } from "../currencyTools";
import i18next from "i18next";

function handleDecimals(number) {
  const round = Math.round(number * 100000) / 100000;
  return round;
}

const handleOrderXlsx = (
  data,
  canSeePrices,
  notSeeProductVolume,
  notSeeProductWeight
) => {
  const { t } = i18next;
  const volumeSum = data.orderProducts
    .map((item) => {
      const { product, orderOrderProduct } = item;
      const vol =
        (product.logisticData.volume / 1000000) * orderOrderProduct.quantity;
      return vol;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const grossWeightSum = data.orderProducts
    .map((item) => {
      const { product, orderOrderProduct } = item;
      const vol = product.logisticData.grossWeight * orderOrderProduct.quantity;
      return vol;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return [
    {
      data: [
        [t("Orders.order_date"), data?.createdOrderDate],
        [
          t("Orders.order_value"),
          {
            v: data?.netOrderValue,
            t: "n",
            z: `0.00`,
          },
        ],
        [
          t("Xlsx.value"),
          {
            v: data.netOrderValue - data.netShipCost,
            t: "n",
            z: `0.00`,
          },
        ],
        [
          t("Orders.cost"),
          {
            v: data?.netShipCost,
            t: "n",
            z: `0.00`,
          },
        ],
        [
          t("Orders.tax"),
          {
            v: data?.taxOrderValue,
            t: "n",
            z: `0.00`,
          },
        ],
        [
          t("Orders.payment"),
          {
            v: data?.grossOrderValue,
            t: "n",
            z: `0.00`,
          },
        ],
        [
          t("Orders.volume"),
          notSeeProductVolume
            ? "-"
            : {
                v: handleDecimals(volumeSum),
                t: "n",
                z: "0.00000",
              },
          "m3",
        ],
        [
          t("Orders.weight"),
          notSeeProductWeight
            ? "-"
            : {
                v: handleDecimals(grossWeightSum),
                t: "n",
                z: "0.000",
              },
          "kg",
        ],
        [],
        [
          t("Xlsx.product"),
          "EAN",
          t("Xlsx.net_price"),
          t("Xlsx.quantity"),
          t("Xlsx.unit"),
          t("Xlsx.net_total"),
          t("Orders.volume") + " m3",
          t("Orders.weight") + " kg",
        ],
        ...data.orderProducts?.map((product) => [
          product.product.name,
          product.product.ean,
          { v: product.orderOrderProduct.netUnitPrice, t: "n", z: `0.00` },
          { v: product.orderOrderProduct.quantity, t: "n", z: `0` },
          product.orderOrderProduct.unit,
          { v: product.orderOrderProduct.netPriceValue, t: "n", z: `0.00` },
          notSeeProductVolume
            ? "-"
            : {
                v: handleDecimals(
                  (product.product.logisticData.volume / 1000000) *
                    product.orderOrderProduct.quantity
                ),
                t: "n",
                z: "0.00000",
              },
          notSeeProductWeight
            ? "-"
            : {
                v: handleDecimals(
                  product.product.logisticData.grossWeight *
                    product.orderOrderProduct.quantity
                ),
                t: "n",
                z: "0.000",
              },
        ]),
      ],
      sheetName: t("Xlsx.order_summary"),
    },
  ];
};
export default handleOrderXlsx;
