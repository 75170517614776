import React, { useState } from "react";
import Counter from "../product/counter";
import useDidUpdateEffect from "../../customHooks/useDidUpdateEffect";
import { FormSelect } from "react-bootstrap";
import priceAndUnit from "../../helpers/priceAndUnit";

function ProductCell({ color, item, handleSelectedProduct, product }) {
  const [quantity, setQuantity] = useState(0);
  const defaultUnit =
    product?.availableUnits &&
    product?.availableUnits[0]?.displayedUnit !== null
      ? product?.availableUnits[0]?.displayedUnit
      : product?.priceList?.conditionUnit;
  const [unit, setUnit] = useState(defaultUnit);
  const [cartReadyData, setCartReadyData] = useState(null);

  useDidUpdateEffect(() => {
    const cartData = priceAndUnit(product, quantity, unit);
    handleSelectedProduct(
      cartData?.cartQuantity,
      unit,
      product.sapIndex,
      cartData?.defaultUnit?.unit,
      quantity
    );
    setCartReadyData(cartData);
  }, [quantity, unit]);

  return product === null ? (
    <td></td>
  ) : (
    <td
      style={{
        backgroundColor: product.productStatuses?.length
          ? product.productStatuses[0].color
          : "#F5F5F5",
      }}
    >
      <Counter
        setQuantity={setQuantity}
        quantity={quantity}
        className="m-auto small"
        allowZero={true}
      />

      <FormSelect
        size="sm"
        className="w-75 m-auto text-center p-0 mt-1"
        onChange={(e) => setUnit(e.target.value)}
        value={unit}
        style={{
          maxWidth: "100px",
        }}
      >
        {product.availableUnits?.map((collection, index) => (
          <option value={collection.unit} key={index}>
            {collection.unit}
          </option>
        ))}
      </FormSelect>
      <span className="d-block" style={{ fontSize: "10px", minHeight: "15px" }}>
        {cartReadyData?.cartQuantity}{" "}
        {cartReadyData?.defaultUnit?.shortUnitName}
        {cartReadyData?.defaultUnit?.unit !== cartReadyData?.baseUnit?.unit ? (
          <>
            {" "}
            = {cartReadyData?.baseQuantity}{" "}
            {cartReadyData?.baseUnit?.shortUnitName}
          </>
        ) : null}
      </span>
    </td>
  );
}

export default ProductCell;
