import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  FormControl,
  Row,
  Spinner,
  Toast,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-perfect-scrollbar/dist/css/styles.css";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getCartCount } from "../../redux/cartSlice";
import SummaryTable from "../summaryTable/summaryTable";
import { default as useCashBoxSum } from "./useCashBoxSum";
import { useTranslation } from "react-i18next";
import round from "../../helpers/round";
import BuyerSelect from "./buyerSelect";

function CartModal(props) {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="pb-1">
        <Modal.Title className="text-danger" id="contained-modal-title-vcenter">
          {t("Cashbox.limit_exceed")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-4">
        <p>
          {t("Cashbox.limit_text", {
            val: props.limit ?? "",
            currency: props.currency ?? "",
          })}
        </p>
      </Modal.Body>
      <Modal.Footer className="mt-2">
        <Button onClick={props.onHide} variant="dark" className="me-auto">
          {t("Cashbox.Understand")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const CashBox = ({ cashRegister, setShowThanks, minLogistic, scrollTop }) => {
  const { t } = useTranslation();
  const [toast, setToast] = useState(false);
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();
  const [address, setAddress] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [newAddress, setNewAddress] = useState(true);
  const [showComment, setShowComment] = useState(false);
  const [loading, setLoading] = useState(false);
  // const cart = useSelector((state) => state.cart.cart);
  const { register, handleSubmit, reset, unregister, setValue, watch } =
    useForm();
  const userData = useSelector((state) => state.user.data);
  const [items, setItems] = useState(cashRegister.items);
  const products = items?.map((item) => {
    return {
      productSapIndex: item.product.sapIndex,
      quantity: item.quantity,
      unit: item.unit,
      uniqueKey: item.uniqueKey,
      customerOrderNumber: item.customerOrderNumber,
    };
  });
  const {
    netShipping,
    taxValue,
    netWholeOrderValue,
    grossWholeOrderValue,
    tax,
    currency,
  } = useCashBoxSum(cashRegister, newAddress, minLogistic);
  const itemsWeight = cashRegister.items.reduce(
    (reducer, item) => reducer + item.weight,
    0
  );
  const offersWeight = cashRegister.offers.reduce((reducer, item) => {
    return reducer + item.reduce((r, elem) => r + elem.weight, 0);
  }, 0);
  const itemsVolume = cashRegister.items.reduce(
    (reducer, item) => reducer + item.orderVolume,
    0
  );
  const offersVolume = cashRegister.offers.reduce((reducer, item) => {
    return reducer + item.reduce((r, elem) => r + elem.orderVolume, 0);
  }, 0);

  const onSubmit = (values) => {
    setLoading(true);
    const { supplementData, oneTimeAddress, ...rest } = values;

    const data = {
      products: products,
      onlyAccessProduct: cashRegister.items?.every(
        (item) => item?.availableNow
      ),
      supplementData: [
        {
          clientOrderNumber: supplementData.clientOrderNumber,
          comment: supplementData.comment,
        },
      ],
      oneTimeAddress: oneTimeAddress ? [{ ...oneTimeAddress }] : null,
      offers: cashRegister.offers.map((item) => {
        return {
          offerId: item[0].offerId,
        };
      }),
      ...rest,
    };

    axios
      .post("/order", JSON.stringify(data))
      .then(() => {
        setShowThanks(true);
        dispatch(getCartCount());
        setLoading(false);
      })
      .catch((error) => {
        setMsg(error.response.data.message.content);
        setLoading(false);
        setToast(true);
      });
  };
  useEffect(() => {
    scrollTop();
    setModalShow(
      cashRegister?.creditLimit && parseFloat(userData?.customerLimit) > 1
    );
    axios.get("/user/address").then((response) => {
      setAddress(response.data);
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CartModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        currency={userData?.currency}
        limit={userData?.customerLimit}
      />

      <div className="secondary-wrapper">
        <h3 className="first-header h3 mb-4">{t("Cashbox.choose_adress")}</h3>
        {address ? (
          <div className="shadow-radius-white adress-cart mb-3">
            <div className=" buttons-header d-flex justify-content-between mb-3">
              <Button
                variant="link"
                className="icon-action rounded"
                to="/B2B-List"
              >
                <img src="/img/orange-done.svg" alt="" />
              </Button>
            </div>
            <div className="fz-18 mb-3">{t("Cashbox.adress")}</div>
            <div className="fz-12">
              <div className="fw-bold">{address.fullName}</div>
              <div>{address.street}</div>
              <div>{`${address.postCode} ${address.city}`}</div>
              <div>{address.country}</div>
            </div>
          </div>
        ) : null}

        {userData?.customerData?.canNotAddOneTimeAddress ? null : (
          <div className="mb-50 d-flex flex-wrap align-items-center">
            <Button
              variant={newAddress ? "dark" : "primary"}
              className="w-290"
              onClick={() => {
                if (!newAddress) {
                  reset();
                }
                setNewAddress((state) => !state);
              }}
            >
              {t("Cashbox.disposable_adress")}
            </Button>
            {newAddress ? null : (
              <span className={"ms-2 fw-bold fs-14 mt-2 mt-sm-0"}>
                {" "}
                {t("Cashbox.delivery_change")}
              </span>
            )}
          </div>
        )}
        {userData?.customerData?.canOrderForBuyer ? (
          <BuyerSelect setValue={setValue} watch={watch} />
        ) : null}

        {newAddress ? null : (
          <>
            <div>
              <h3 className="h3 mb-4">
                {t("Cashbox.enter_disposable_adress")}
              </h3>
              <div className="cashbox-adress-form me-3">
                <Row>
                  <Col sm={6}>
                    <Form.Control
                      className=" ps-3 h-40 mt-3 "
                      type="text"
                      placeholder={t("Pop.name")}
                      required
                      {...register("oneTimeAddress.firstName", { value: null })}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Control
                      required
                      className=" ps-3 h-40 mt-3"
                      type="text"
                      placeholder={t("Pop.surname")}
                      {...register("oneTimeAddress.lastName", { value: null })}
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Control
                      required
                      name={"email"}
                      className=" ps-3 h-40 mt-3"
                      type="email"
                      placeholder={t("Pop.mail")}
                      {...register("oneTimeAddress.email", { value: null })}
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Control
                      name={"phoneNumber"}
                      className=" ps-3 h-40 mt-3"
                      type="text"
                      placeholder={t("Pop.tel")}
                      required
                      {...register("oneTimeAddress.phoneNumber", {
                        value: null,
                        onChange: (e) => {
                          let validInput = e.target.value.replace(
                            /[^+\d]/g,
                            ""
                          );
                          if (validInput.length > 14) {
                            validInput = validInput.substring(0, 14);
                          }
                          e.target.value = validInput;
                        },
                      })}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Control
                      name={"companyName"}
                      className=" ps-3 h-40 mt-3"
                      type="text"
                      placeholder={t("Pop.company_name")}
                      required
                      {...register("oneTimeAddress.companyName", {
                        value: null,
                      })}
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Control
                      required
                      name={"address"}
                      className=" ps-3 h-40 mt-3"
                      type="text"
                      placeholder={t("Pop.street_number")}
                      {...register("oneTimeAddress.address", { value: null })}
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Control
                      required
                      name={"postCode"}
                      className=" ps-3 h-40 mt-3"
                      type="text"
                      placeholder={t("Pop.zip_code")}
                      {...register("oneTimeAddress.postCode", { value: null })}
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Control
                      required
                      name={"city"}
                      className=" ps-3 h-40 mt-3"
                      type="text"
                      placeholder={t("Pop.city")}
                      {...register("oneTimeAddress.city", { value: null })}
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Control
                      required
                      name={"country"}
                      className=" ps-3 h-40 mt-3 mb-3"
                      type="text"
                      placeholder={t("Pop.country")}
                      readOnly={
                        userData?.customerData?.recipient?.country === "PL"
                      }
                      disabled={
                        userData?.customerData?.recipient?.country === "PL"
                      }
                      {...register("oneTimeAddress.country", {
                        value:
                          userData?.customerData?.recipient?.country === "PL"
                            ? "PL"
                            : null,
                      })}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
        <div className="mb-50">
          <h3 className="h3 mb-4">{t("Cashbox.enter_own_number")}</h3>
          <div className="w-290">
            <Form.Group className="mb-3">
              <Form.Control
                className="h-40 ps-3 border-black-80"
                type="text"
                maxLength={35}
                placeholder={t("Pop.order_number")}
                {...register("supplementData.clientOrderNumber", {
                  value: null,
                })}
              />
            </Form.Group>
          </div>
        </div>
      </div>
      <div className={"ms-3 m-xl-0 "}>
        <h3>{t("Cashbox.order_summary")}</h3>
      </div>
      <div className="main-content with-header">
        {items ? (
          <SummaryTable
            items={items}
            setItems={setItems}
            offers={cashRegister.offers}
            netShipping={netShipping}
            netWholeOrderValue={netWholeOrderValue}
            currency={currency}
            taxValue={tax}
            grossWholeOrderValue={grossWholeOrderValue}
            taxWholeOrderValue={taxValue}
            productOrderNumber={"input"}
            sumVolume={round(itemsVolume + offersVolume)}
            sumWeight={round(itemsWeight + offersWeight)}
          />
        ) : null}

        {showComment ? (
          <Container className={"mw-100"}>
            <Row
              className={"mt-3 justify-content-lg-end justify-content-center"}
            >
              <Col sm={6} md={4}>
                <FormControl
                  className={""}
                  name={"comment"}
                  placeholder={t("Cashbox.comment")}
                  as={"textarea"}
                  {...register("supplementData.comment", { value: null })}
                />
              </Col>
            </Row>
          </Container>
        ) : null}

        <div className="d-flex flex-column justify-content-end align-items-lg-end align-items-center">
          <div>
            <div
              className=" d-flex gap order-buttons"
              // style={subTableWidth}
            >
              <Button
                variant={showComment ? "danger" : "outline-dark"}
                onClick={() => {
                  if (showComment) {
                    unregister("supplementData.comment");
                  }
                  setShowComment((state) => !state);
                }}
              >
                {showComment
                  ? t("Cashbox.delete_comment")
                  : t("Cashbox.add_comment")}
              </Button>
              <Button variant="success" type={"submit"} disabled={loading}>
                {loading ? (
                  <Spinner animation={"border"} size={"sm"} />
                ) : (
                  t("Cashbox.order")
                )}
              </Button>
            </div>
            <Toast show={toast} onClose={() => setToast(false)} bg={"warning"}>
              <Toast.Header
                closeButton={true}
                className={" justify-content-between"}
              >
                {t("Cashbox.error")}
              </Toast.Header>
              <Toast.Body>{msg}</Toast.Body>
            </Toast>
          </div>
        </div>
      </div>
    </Form>
  );
};
export default CashBox;
