// import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ReactComponent as Done } from "../../svg/done_black_24dp (5).svg";
import { Overlay, Tooltip } from "react-bootstrap";

function AddToCart({
  className = "",
  buttonText = "Dodaj",
  productSumValue = "",
  netto = "",
  item,
  quantity,
  unit,
  setQuantity,
  onAddingComplete = () => console.log("added"),
  selectedUnit,
  priceLoading,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [showMark, setShowMark] = useState(false);
  const dispatch = useDispatch();
  const onCLick = (e) => {
    setLoading(true);
    dispatch(
      addToCart({
        itemData: item,
        quantity: quantity,
        unit: unit,
        selectedUnit: selectedUnit,
      })
    ).then((response) => {
      console.log(response);
      setLoading(false);

      if (response.payload === 410) {
        setShow(true);
        setTimeout(() => setShow(false), 5000);
        return;
      }

      setQuantity(1);

      setShowMark(true);
      onAddingComplete();
      setTimeout(() => {
        setShowMark(false);
      }, 500);
    });
  };
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <div>
      <Button
        ref={target}
        variant="success"
        onClick={onCLick}
        className={`${className}`}
        disabled={loading || priceLoading}
        {...props}
      >
        {loading || priceLoading ? (
          <Spinner animation={"border"} size={"sm"} />
        ) : showMark ? (
          <Done />
        ) : (
          <>
            {buttonText} {productSumValue} {netto}
          </>
        )}
      </Button>{" "}
      <Overlay
        show={show}
        target={target}
        placement="top"
        containerPadding={20}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Koszyk tymczasowo zablokowany
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
}
export default AddToCart;
